import _errorEx from "error-ex";
import _parse from "./vendor/parse";
var exports = {};
var errorEx = _errorEx;
var fallback = _parse;
var JSONError = errorEx("JSONError", {
  fileName: errorEx.append("in %s")
});

exports = function (x, reviver, filename) {
  if (typeof reviver === "string") {
    filename = reviver;
    reviver = null;
  }

  try {
    try {
      return JSON.parse(x, reviver);
    } catch (err) {
      fallback.parse(x, {
        mode: "json",
        reviver: reviver
      });
      throw err;
    }
  } catch (err) {
    var jsonErr = new JSONError(err);

    if (filename) {
      jsonErr.fileName = filename;
    }

    throw jsonErr;
  }
};

export default exports;